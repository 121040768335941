import { makeStyles } from "@material-ui/core";
import { anime } from "../libs/utils";
import { Howl } from "howler";
import clsx from "clsx";

const useStyles = makeStyles({
    svg: {
        filter: "drop-shadow(0.33vh 0.66vh 0.8vh black)",
        height: "10vh",
        outline: "none"
    }
});

const CIClose = (props) => {
    const cls = useStyles();

    const handleClick = () => {
        new Howl({
            src: require("../assets/sounds/Click.mp3"),
            autoplay: true
        });
        anime({
            targets: "#" + props.id,
            scale: [0.9, 1],
            duration: 1000
        });
        anime({
            targets: `#${props.id} path[fill="#04826A"],#${props.id} path[fill="#1AC1A1"],#${props.id} path[fill="#46E0C2"]`,
            filter: "sepia(1)",
            easing: "linear",
            duration: 1000
        });
        props.onClick && props.onClick();
    }

    return <svg className={clsx(cls.svg, props.className, "pointer")} id={props.id} onClick={handleClick} viewBox="0 0 100 111" data-tooltip-id="tooltip"
        data-tooltip-content={props.tooltip || "Leave Game"}>
        <path className="pointer" opacity="0.3" d="M99.9987 58.4267C100.196 86.8849 77.9741 110.112 50.3618 110.305C22.7441 110.498 0.198183 87.5854 0.00134455 59.1254C-0.19904 30.6672 22.0242 7.43852 49.6383 7.247C77.2506 7.05371 99.7965 29.9667 99.9987 58.4267Z" fill="black" />
        <path className="pointer" d="M99.9987 51.1792C100.196 79.6374 77.9741 102.864 50.3618 103.058C22.7441 103.253 0.198183 80.3396 0.00134455 51.8796C-0.19904 23.4214 22.0242 0.192754 49.6383 0.0012362C77.2506 -0.193829 99.7965 22.721 99.9987 51.1792Z" fill="#552800" />
        <path className="pointer" d="M9.67122 26.1045C8.12311 30.8055 22.5898 22.6926 48.6026 23.5509C74.6154 24.4039 83.7179 22.8646 85.1489 19.6939C86.5835 16.5232 72.1363 4.76261 57.8168 2.33848C43.4955 -0.0838678 16.6475 4.9098 9.67122 26.1045Z" fill="#A85C09" />
        <path className="pointer" d="M10.9072 25.3721C7.36946 26.3048 5.19715 36.2939 5.25212 44.0504C6.36931 46.205 20.4211 47.0136 20.4211 47.0136C20.4211 47.0136 7.91209 46.8558 6.65481 49.5725C5.39753 52.291 7.6656 61.5034 12.0067 68.4903C13.725 71.2567 79.7102 77.7576 84.7109 73.0974C95.1682 63.3477 95.6824 40.0658 93.7761 36.6699C91.8734 33.274 73.1205 34.0489 73.1205 34.0489C73.1205 34.0489 89.7666 34.4107 90.7916 32.2047C92.0471 29.5004 88.0341 26.3527 83.9626 25.4749C79.8928 24.5971 21.0293 22.705 10.9072 25.3721Z" fill="#A85C09" />
        <path className="pointer" d="M12.2337 66.9546C10.0933 69.948 20.7385 88.9757 46.9889 90.5078C72.381 91.9921 76.3781 83.0262 75.8585 82.4481C75.3336 81.87 57.2564 80.8805 57.2564 80.8805C57.2564 80.8805 76.1014 80.3662 78.9317 79.2756C81.7654 78.185 87.4081 70.6839 85.0656 69.0985C82.7248 67.515 15.0497 63.0178 12.2337 66.9546Z" fill="#A85C09" />
        <path className="pointer" d="M22.2476 50.2907C21.0736 49.1913 10.831 50.2872 9.55772 52.1173C8.2827 53.9491 9.73327 60.4199 9.55772 60.6629C9.38038 60.9094 5.23082 52.2662 7.67268 50.0513C10.1128 47.84 19.3925 48.2106 19.6355 46.9906C19.882 45.7688 12.6646 45.5258 10.0472 44.1817C14.3865 43.9387 22.4834 44.7793 23.3346 43.2117C23.9127 42.1477 16.2147 34.7689 15.9736 33.1995C15.7324 31.6284 27.3157 16.9134 36.365 14.679C30.2115 18.1157 18.6282 30.7878 19.2312 33.0789C19.8341 35.3701 29.1227 44.1019 29.6086 45.9195C30.0927 47.7354 19.3517 57.4709 19.4741 60.7196C19.5947 63.9666 28.6546 72.8739 35.5882 75.9613C28.5233 74.344 16.94 63.1597 17.0624 60.3064C17.1794 57.4496 23.1236 51.1118 22.2476 50.2907Z" fill="#B76400" />
        <path className="pointer" d="M45.4125 3.40955C26.1046 1.57772 12.2337 17.6919 9.55603 28.862C14.571 20.4369 26.6295 6.33907 45.4125 3.40955Z" fill="#B76400" />
        <path className="pointer" d="M37.0495 15.649C34.6733 15.4787 18.6407 30.3054 20.1268 33.5737C21.6128 36.8437 30.8217 45.2474 30.7968 46.5809C30.7685 47.9145 19.7065 57.7635 19.9902 61.1807C20.2757 64.5979 34.1856 75.9577 37.3031 75.9577C40.4242 75.9577 49.4592 65.8321 50.3512 65.6831C51.2414 65.5342 62.0214 75.6616 64.5466 75.9577C67.0736 76.2539 82.1042 61.5602 80.9179 58.143C79.728 54.7258 71.0511 47.3506 71.0511 46.2352C71.0511 45.118 81.629 33.8308 81.3346 31.7507C81.0385 29.6706 63.9951 14.4254 61.9132 15.7607C59.8349 17.0995 52.8214 26.5105 50.9914 26.3634C49.1666 26.2109 39.1296 15.7997 37.0495 15.649Z" fill="#3F100B" />
        <path className="pointer" d="M38.4966 22.1269C36.5442 21.9868 26.0532 31.3765 27.275 34.0631C28.4969 36.7479 38.3264 45.6677 37.5053 46.3912C36.6825 47.1165 26.9292 56.4016 27.1616 59.207C27.3974 62.0159 34.7194 69.7068 37.2819 69.7068C39.8461 69.7068 49.7323 57.744 50.4665 57.6234C51.1971 57.4993 61.6969 69.4639 63.7717 69.7068C65.8482 69.9515 75.7345 61.1629 74.7609 58.3522C73.7821 55.5469 63.7947 47.1271 63.7947 46.2103C63.7947 45.2935 74.5145 34.3061 74.2697 32.5984C74.025 30.8907 64.1388 21.6127 62.4275 22.7121C60.7216 23.8098 52.4952 34.0915 50.9914 33.9709C49.4929 33.8468 40.2061 22.2493 38.4966 22.1269Z" fill="#04826A" />
        <path className="pointer" d="M39.55 24.0581C38.2377 24.2265 31.2136 31.1194 31.3927 32.7562C31.57 34.393 40.8427 45.1659 40.8427 45.1659C40.8427 45.1659 30.6302 55.82 30.2507 56.6623C29.873 57.5046 37.3954 64.0286 38.7981 63.5108C40.2008 62.993 50.165 51.5463 50.9932 51.4044C51.8213 51.2643 62.8247 63.33 63.566 63.4701C64.3072 63.6101 71.8899 57.0772 72.03 56.0948C72.1701 55.1124 60.0282 45.384 60.5886 44.0043C61.149 42.6247 70.1539 32.8378 70.012 31.8589C69.8701 30.8782 64.3658 24.8224 62.9046 24.8224C61.4433 24.8224 51.9454 34.4018 50.9932 34.4018C50.0409 34.4018 40.7629 23.9002 39.55 24.0581Z" fill="#1AC1A1" />
        <path className="pointer" d="M33.5348 60.9431C35.1946 61.5389 39.3211 58.5899 40.5944 57.6358C41.8676 56.6818 42.8571 56.2278 40.8426 58.2653C38.8264 60.3047 36.3455 63.5392 36.3455 63.5392" fill="#04826A" />
        <path className="pointer" d="M50.0692 34.8877C50.0692 34.8877 41.0288 23.409 39.7999 23.5988C38.5692 23.7885 30.5539 31.2577 30.9085 32.8377C31.2632 34.4195 37.0708 39.415 37.0708 39.415C37.0708 39.415 33.3291 33.8024 33.7476 33.1764C34.1643 32.5505 38.5551 26.7499 40.1227 26.695C41.6885 26.6435 49.8937 35.3949 50.0692 34.8877Z" fill="#46E0C2" />
        <path className="pointer" d="M36.4271 50.2907C36.1291 50.5709 30.6638 55.8873 30.6957 56.8272C30.7276 57.7653 33.9887 60.6416 34.6059 60.7143C35.223 60.787 37.429 59.8383 39.486 58.1217C39.486 58.1217 35.3968 59.629 34.597 59.1609C33.799 58.6909 32.3378 56.8396 32.3218 56.2615C32.3041 55.6852 36.4271 50.2907 36.4271 50.2907Z" fill="#46E0C2" />
        <path className="pointer" d="M60.2002 45.1658C61 46.0188 71.6877 55.0521 72.0335 55.804C72.3793 56.5576 65.5893 62.2074 65.5893 62.2074C65.5893 62.2074 68.9214 57.3148 68.6199 56.2952C68.3202 55.2755 60.2002 45.1658 60.2002 45.1658Z" fill="#46E0C2" />
        <path className="pointer" d="M72.0318 34.2121C72.0318 34.2121 59.193 37.2108 58.3187 36.0865C57.4445 34.9622 57.6395 27.6934 57.6395 27.6934L55.4353 30.0998C55.4353 30.0998 56.4479 36.7089 57.6963 37.5832C58.9447 38.4556 67.4353 38.7057 67.4353 38.7057" fill="#04826A" />
        <path className="pointer" d="M42.499 63.8442C42.499 63.8442 45.1395 50.9256 43.9921 50.085C42.8448 49.2427 35.5848 49.6364 35.5848 49.6364L37.9292 47.3665C37.9292 47.3665 44.5631 48.1947 45.4711 49.4183C46.379 50.6436 46.8631 59.1219 46.8631 59.1219" fill="#04826A" />
        <path className="pointer" d="M43.2491 26.5176C43.2491 26.5176 46.1875 37.7764 45.0029 38.5673C43.8201 39.3565 35.5848 39.6349 35.5848 39.6349L37.8263 42.0076C37.8263 42.0076 45.4906 40.479 46.4517 39.2979C47.4146 38.1169 46.8153 30.348 46.8153 30.348" fill="#04826A" />
        <path className="pointer" d="M68.9444 52.1864C68.9444 52.1864 58.684 50.195 57.8045 51.1934C57.1891 51.8956 58.4358 60.5955 58.4358 60.5955L56.4798 58.2565C56.4798 58.2565 56.0436 51.0284 57.2441 50.2641C58.4464 49.5034 65.3304 48.9164 65.3304 48.9164" fill="#04826A" />
        <path className="pointer" d="M72.6134 46.4657C72.8244 47.2938 81.1537 55.7596 81.3434 58.0933C81.5332 60.427 72.9255 73.218 64.6405 76.5145C63.3531 76.2734 52.4135 67.7455 52.4135 67.7455C52.4135 67.7455 62.8708 78.3641 65.7666 78.928C68.6624 79.4902 84.8315 61.3119 84.3492 57.8522C83.8668 54.3924 75.5269 47.1041 75.4737 45.7209C75.4205 44.3377 80.5046 35.2495 80.5046 35.2495C80.5046 35.2495 71.9697 43.9423 72.6134 46.4657Z" fill="#B76400" />
    </svg>
}

export default CIClose;