import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
    cross: {
        width: "15vw"
    },
    shell: {
        width: "14vw"
    }
});

const Shell = ({ alt, className, id, pointer, onClick }) => {
    const cls = useStyles();

    return alt ? <svg className={clsx(cls.shell, className)} id={id} viewBox="0 0 108 114">
        <path fillRule="evenodd" clipRule="evenodd" d="M88.1024 112.192C85.8056 114.001 22.9578 114.988 20.7941 112.536C20.084 111.704 19.8398 108.453 19.8398 104.503C19.8398 103.049 19.8842 101.496 19.9397 99.9312V99.9201C19.9841 98.3556 20.0618 96.791 20.1505 95.2931L20.1616 95.1599C20.4279 90.2444 20.7941 86.2165 20.7941 86.2165L87.6364 80.258C87.6364 80.258 88.0137 84.419 88.3909 89.734C88.5574 92.275 88.7349 95.0712 88.857 97.8119C88.8681 98.0116 88.8681 98.2002 88.8792 98.4C89.1455 105.146 89.1122 111.393 88.1024 112.192Z" fill="url(#paint0_linear_1802_5028)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M107.808 47.0144C100.607 93.2403 67.0194 107.31 54.3589 111.005C53.4379 111.282 52.639 111.493 51.9622 111.66C51.9067 111.671 51.8512 111.693 51.7957 111.704C51.6404 111.737 51.4961 111.771 51.3519 111.804C51.2631 111.826 51.1744 111.848 51.0856 111.871C50.9302 111.904 50.786 111.937 50.6528 111.97C50.5752 111.981 50.5086 111.993 50.442 112.004C49.8317 112.137 49.4989 112.192 49.4989 112.192C49.055 112.048 48.6223 111.893 48.2006 111.737C48.1895 111.737 48.1784 111.726 48.1673 111.726C4.10513 96.1586 0.43235 59.2311 0.43235 59.2311C-2.11973 42.9644 7.37845 37.7603 9.29805 36.7284C9.49778 36.6175 9.60874 36.562 9.60874 36.562C9.68641 36.107 9.76409 35.6743 9.84176 35.2416C14.2802 11.4739 26.7632 16.9332 29.0489 18.1537C29.2819 18.2869 29.4151 18.3645 29.4151 18.3645C29.5261 17.9984 29.637 17.6433 29.748 17.2993V17.266C37.1934 -5.21445 50.3643 7.05772 52.7722 9.56542C53.0274 9.83172 53.1605 9.98707 53.1605 9.98707C53.2715 9.80953 53.3714 9.63199 53.4823 9.45446C66.0652 -11.4615 75.9628 8.28938 77.716 12.173C77.8935 12.5724 77.9823 12.8054 77.9823 12.8054C78.1376 12.7389 78.2819 12.6834 78.4261 12.6279C92.1186 7.10211 95.292 25.7989 95.7803 29.4716C95.8357 29.86 95.8579 30.0819 95.8579 30.0819C96.0355 30.0375 96.2019 30.0042 96.3684 29.9821C108.064 27.8294 107.808 47.0144 107.808 47.0144Z" fill="url(#paint1_linear_1802_5028)" />
        <path d="M53.4831 9.45438L51.7965 111.704L51.7854 111.859L51.7743 112.791C51.7743 112.902 51.7188 113.013 51.6301 113.08C51.5635 113.124 51.508 113.146 51.4303 113.146C51.386 113.146 51.3305 113.135 51.2861 113.113C51.0753 113.047 49.9546 112.658 48.2014 111.737C48.1903 111.737 48.1792 111.726 48.1681 111.726C44.7727 109.951 39.0361 106.245 32.9777 99.1988C24.4005 89.2235 13.4043 70.3381 9.29883 36.7283C9.49856 36.6174 9.60952 36.5619 9.60952 36.5619C9.68719 36.107 9.76486 35.6742 9.84253 35.2415C16.6555 94.3277 44.5952 109.463 50.4428 112.004C50.5538 112.059 50.6425 112.092 50.7313 112.126C50.8644 112.181 50.9754 112.237 51.0753 112.27L51.0864 111.87L51.0975 111.271V111.26L52.773 9.56534C53.0282 9.83164 53.1613 9.98698 53.1613 9.98698C53.2723 9.80945 53.3721 9.63191 53.4831 9.45438Z" fill="url(#paint2_linear_1802_5028)" />
        <path d="M54.3577 111.005C53.47 111.715 52.5601 112.403 51.6392 113.08H51.6281C51.5615 113.124 51.506 113.147 51.4283 113.147C51.384 113.147 51.3285 113.136 51.2841 113.113C51.2397 113.091 51.1953 113.058 51.162 113.014C51.1398 112.991 51.1287 112.969 51.1177 112.947C51.1066 112.936 51.1066 112.914 51.0955 112.903C51.0511 112.781 51.0844 112.625 51.1842 112.525C51.2064 112.503 51.3174 112.381 51.5282 112.159C51.6059 112.07 51.6946 111.97 51.7834 111.86C51.85 111.804 51.9055 111.737 51.961 111.66C56.5658 106.345 79.8452 76.1526 77.7148 12.1731C77.8923 12.5726 77.9811 12.8056 77.9811 12.8056C78.1364 12.739 78.2807 12.6835 78.4249 12.628C80.3001 71.7475 60.4827 102.051 53.8251 110.528C98.2757 75.9529 95.6681 29.96 95.6348 29.4829L95.7791 29.4718C95.8345 29.8601 95.8567 30.082 95.8567 30.082C96.0343 30.0377 96.2007 30.0044 96.3671 29.9822C96.5225 35.2861 96.4115 78.2387 54.3577 111.005Z" fill="url(#paint3_linear_1802_5028)" />
        <path d="M51.7532 112.636L51.1207 112.947C51.1096 112.936 51.1096 112.914 51.0985 112.903C50.9765 112.648 50.8433 112.381 50.7324 112.126C50.6991 112.071 50.6769 112.026 50.6547 111.971C30.4045 69.6283 29.0952 26.8089 29.0508 18.154C29.2838 18.2871 29.4169 18.3648 29.4169 18.3648C29.5279 17.9986 29.6389 17.6436 29.7498 17.2996C29.7276 23.3358 30.3046 67.4979 51.0985 111.261V111.272C51.1873 111.449 51.265 111.627 51.3537 111.804C51.4092 111.926 51.4647 112.037 51.5313 112.159C51.5979 112.315 51.6755 112.481 51.7532 112.636Z" fill="url(#paint4_linear_1802_5028)" />
        <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M105.877 56.6233C105.877 56.6344 105.877 56.6344 105.877 56.6455C104.889 60.5069 103.691 64.0799 102.359 67.3865C102.559 65.3226 102.57 63.4585 102.448 61.7608C101.616 50.2431 94.4146 46.9476 94.4146 46.9476C95.4798 27.2079 78.048 27.2744 78.048 27.2744C68.4388 13.36 53.1596 28.2287 53.1596 28.2287C35.2618 21.8818 30.2353 35.6408 30.2353 35.6408C16.1545 31.5131 11.7826 50.7203 11.7826 50.7203C2.26228 60.0853 4.27066 72.646 4.27066 72.646L2.75051 68.6514C1.0972 47.9241 10.5621 43.641 10.5621 43.641C14.4346 19.5294 29.4031 28.2287 29.4031 28.2287C45.5256 10.1644 53.1596 20.7944 53.1596 20.7944C68.6164 0.000462532 78.1256 21.327 78.1256 21.327C93.2273 14.7249 95.5796 38.7033 95.5796 38.7033C102.759 36.3953 104.967 47.447 105.633 53.6718C105.766 54.8813 105.832 55.9132 105.877 56.6233Z" fill="url(#paint5_linear_1802_5028)" />
        <g opacity="0.5">
            <path d="M99.6536 74.0333C99.3651 74.6214 99.0766 75.1984 98.777 75.7643L98.0225 75.6312C98.2999 74.0112 98.4108 72.5243 98.3442 71.1928C98.2555 68.7295 97.5897 66.7433 96.3803 65.2786C94.1167 62.549 90.6436 62.5601 90.5549 62.5379L89.8003 62.549L89.8447 61.7834C90.1998 55.8026 89.0347 51.8192 86.3939 49.9661C82.5214 47.2365 76.6183 49.8995 76.5628 49.9217L75.9636 50.188L75.6418 49.611C73.4559 45.5832 70.571 43.1754 67.0868 42.4652C60.1407 41.0338 52.9394 46.7372 52.8728 46.8038L52.4956 47.1034L52.085 46.8592C47.8796 44.4625 44.1292 43.9077 40.9335 45.2281C35.3966 47.5361 33.2662 54.7596 33.2329 54.8262L32.9777 55.7582L32.1788 55.2367C29.1385 53.2616 26.3534 52.7734 23.9234 53.7831C18.2089 56.1799 15.9343 65.9998 15.9121 66.0997L15.8122 66.5546L15.3684 66.6434C10.4639 67.6975 9.06584 72.7684 8.73296 76.5854C8.51104 79.0265 8.74405 81.2125 8.74405 81.2679C8.74405 81.2679 7.72322 79.5592 7.24609 78.6715C7.25719 77.5175 7.35705 75.8531 7.62336 74.3884C8.31131 70.6491 10.12 66.5435 14.6582 65.3452C15.1797 63.2813 17.6763 54.8484 23.3908 52.4627C26.076 51.342 29.0275 51.7082 32.1899 53.5501C32.9666 51.4086 35.3966 45.9938 40.4009 43.9077C43.9184 42.443 47.9462 42.9424 52.3846 45.3835C54.0268 44.1518 60.6511 39.6801 67.3642 41.0449C71.1147 41.8217 74.2215 44.2739 76.5961 48.3572C78.3271 47.7025 83.4201 46.1158 87.2039 48.7789C90.1776 50.8649 91.5535 55.0148 91.2983 61.1509C92.6187 61.2951 95.4149 61.8832 97.4455 64.3354C98.4774 65.556 99.1654 67.0651 99.4982 68.8404V68.8515C99.8089 70.3828 99.8644 72.1026 99.6536 74.0333Z" fill="url(#paint6_linear_1802_5028)" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M70.4578 16.1233C70.4578 16.1233 67.4841 29.0613 70.1471 33.2001C70.1471 33.2001 74.8518 23.4911 70.4578 16.1233Z" fill="white" />
        <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M88.8769 98.3998C85.3817 100.164 62.0135 111.77 52.6818 112.791C42.5844 113.912 19.9375 99.931 19.9375 99.931V99.9199L20.1483 95.2929L20.1594 95.1597C20.1594 95.1597 35.827 105.856 36.5593 105.59C37.2695 105.335 27.4162 91.4648 26.8281 90.6326C27.6048 91.3982 43.0949 107.032 44.7149 105.734C46.346 104.425 41.9631 90.2331 41.73 89.4786C42.0962 90.2442 48.31 103.571 49.7303 103.315C51.206 103.049 51.8718 86.4605 51.8718 86.4605C51.8718 86.4605 52.7484 104.924 54.7124 105.39C56.6209 105.845 63.3118 95.0377 63.7223 94.3719C63.456 94.96 59.0842 104.258 60.7042 104.114C62.3131 103.981 69.6587 97.3124 70.1136 96.9018L63.0455 105.79C76.2719 102.361 86.7243 91.5314 88.3887 89.7338C88.5552 92.2748 88.7327 95.071 88.8548 97.8117C88.8659 98.0114 88.8659 98.2 88.8769 98.3998Z" fill="url(#paint7_linear_1802_5028)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M90.7318 28.6838C90.7318 28.6838 88.3129 34.1098 89.3227 38.1487C89.3227 38.1487 91.3865 33.6659 90.7318 28.6838Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M39.3688 24.6561C39.3688 24.6561 38.3924 31.6022 40.4118 37.0281C40.4118 37.0281 41.6435 32.279 39.3688 24.6561Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.86719 53.1617C6.86719 53.1617 8.15432 66.5434 16.1434 74.7656C16.1323 74.7545 10.0739 59.7083 6.86719 53.1617Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M89.9668 73.5448C89.9668 73.5448 99.4095 57.5222 100.841 39.6909L89.9668 73.5448Z" fill="white" />
        <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M88.1018 112.192C85.8049 114.001 22.9572 114.989 20.7935 112.536C20.0722 111.704 19.8281 108.453 19.8281 104.503L19.9391 104.303C19.9391 104.303 20.9155 110.739 22.8462 111.671C24.7991 112.614 71.6021 114.589 81.3333 110.583C86.7259 108.353 88.4236 102.539 88.8563 97.8008C89.1448 104.78 89.1448 111.371 88.1018 112.192Z" fill="url(#paint8_linear_1802_5028)" />
        <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M34.5203 105.69C33.2442 105.036 18.5198 97.3572 8.60002 81.0572C8.08961 80.2139 7.57919 79.3817 7.11316 78.4829C3.50696 71.4703 0.544327 64.502 0.0117188 55.0482C0.0117188 55.0482 2.11996 63.3591 7.60138 74.3996C7.95645 75.1209 8.32262 75.8532 8.72208 76.5966C14.2368 87.0934 22.581 98.7886 34.5203 105.69Z" fill="url(#paint9_linear_1802_5028)" />
        <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M107.809 47.0144C107.31 50.3987 106.655 53.5943 105.879 56.6236C105.879 56.6347 105.879 56.6347 105.879 56.6457C104.891 60.5072 103.693 64.0801 102.361 67.3867C101.507 69.4838 100.608 71.4589 99.6537 73.3452C99.3652 73.9222 99.0767 74.4881 98.7771 75.0429C88.247 94.7383 73.2119 102.528 72.5684 102.849C84.1748 94.5275 92.6299 81.7782 98.3444 70.5712C98.7438 69.7945 99.1211 69.0289 99.4873 68.2744V68.2633C100.586 65.9997 101.573 63.8138 102.45 61.761C103.759 58.6985 104.813 55.9467 105.634 53.672C107.11 49.5665 107.809 47.0144 107.809 47.0144Z" fill="url(#paint10_linear_1802_5028)" />
        <defs>
            <linearGradient id="paint0_linear_1802_5028" x1="54.4292" y1="114.001" x2="54.4292" y2="80.2587" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D5A7C9" />
                <stop offset="0.0716" stopColor="#D9ACC9" />
                <stop offset="0.3844" stopColor="#E6BBC8" />
                <stop offset="0.6939" stopColor="#EEC5C7" />
                <stop offset="0.9944" stopColor="#F1C8C6" />
            </linearGradient>
            <linearGradient id="paint1_linear_1802_5028" x1="53.9036" y1="112.193" x2="53.9036" y2="0.000668718" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F4D5CD" />
                <stop offset="1" stopColor="#FEFAE1" />
            </linearGradient>
            <linearGradient id="paint2_linear_1802_5028" x1="31.3899" y1="113.147" x2="31.3899" y2="9.45504" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D5A7C9" />
                <stop offset="0.0716" stopColor="#D9ACC9" />
                <stop offset="0.3844" stopColor="#E6BBC8" />
                <stop offset="0.6939" stopColor="#EEC5C7" />
                <stop offset="0.9944" stopColor="#F1C8C6" />
            </linearGradient>
            <linearGradient id="paint3_linear_1802_5028" x1="73.7287" y1="113.147" x2="73.7287" y2="12.1738" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D5A7C9" />
                <stop offset="0.0716" stopColor="#D9ACC9" />
                <stop offset="0.3844" stopColor="#E6BBC8" />
                <stop offset="0.6939" stopColor="#EEC5C7" />
                <stop offset="0.9944" stopColor="#F1C8C6" />
            </linearGradient>
            <linearGradient id="paint4_linear_1802_5028" x1="40.4009" y1="112.948" x2="40.4009" y2="17.3003" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D5A7C9" />
                <stop offset="0.0716" stopColor="#D9ACC9" />
                <stop offset="0.3844" stopColor="#E6BBC8" />
                <stop offset="0.6939" stopColor="#EEC5C7" />
                <stop offset="0.9944" stopColor="#F1C8C6" />
            </linearGradient>
            <linearGradient id="paint5_linear_1802_5028" x1="54.2149" y1="72.6515" x2="54.2149" y2="11.6894" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C65188" />
                <stop offset="1" stopColor="#FAEAD9" />
            </linearGradient>
            <linearGradient id="paint6_linear_1802_5028" x1="53.5065" y1="81.2704" x2="53.5065" y2="40.7893" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C65188" />
                <stop offset="1" stopColor="#F6DED9" />
            </linearGradient>
            <linearGradient id="paint7_linear_1802_5028" x1="54.4061" y1="112.856" x2="54.4061" y2="86.4611" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C65188" />
                <stop offset="0.7151" stopColor="white" />
            </linearGradient>
            <linearGradient id="paint8_linear_1802_5028" x1="54.43" y1="114.001" x2="54.43" y2="97.8077" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C65188" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient id="paint9_linear_1802_5028" x1="17.269" y1="105.691" x2="17.269" y2="55.0431" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C65188" />
                <stop offset="0.7151" stopColor="white" />
            </linearGradient>
            <linearGradient id="paint10_linear_1802_5028" x1="90.1877" y1="102.85" x2="90.1877" y2="47.0151" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C65188" />
                <stop offset="0.7151" stopColor="white" />
            </linearGradient>
        </defs>
    </svg> : <svg className={clsx(cls.cross, className)} id={id} onClick={onClick} viewBox="0 0 81 80">
        <path className={pointer && "pointer"} d="M22.4293 0.00191142C19.306 -0.221845 -1.76668 19.2659 0.186524 23.5616C2.13973 27.8596 14.2436 38.9052 14.2109 40.658C14.1736 42.4108 -0.365874 55.356 0.00705317 59.8475C0.382311 64.3389 18.6651 79.27 22.7626 79.27C26.8648 79.27 38.7402 65.9611 39.9126 65.7654C41.0827 65.5696 55.2516 78.8807 58.5707 79.27C61.892 79.6592 81.6479 60.3462 80.0886 55.8548C78.5246 51.3634 67.12 41.6696 67.12 40.2035C67.12 38.7351 81.0232 23.8996 80.6363 21.1655C80.2471 18.4315 57.8458 -1.60634 55.1094 0.148751C52.3777 1.9085 43.1594 14.278 40.754 14.0846C38.3557 13.8841 25.1633 0.200029 22.4293 0.00191142Z" fill="#3F100B" />
        <path className={pointer && "pointer"} d="M24.3313 8.51632C21.7651 8.33219 7.97611 20.6738 9.58203 24.2049C11.1879 27.7337 24.1076 39.4576 23.0284 40.4086C21.9469 41.3619 9.12752 53.566 9.43286 57.2533C9.74285 60.9453 19.3667 71.0539 22.7347 71.0539C26.1051 71.0539 39.0992 55.3304 40.0642 55.1719C41.0245 55.0087 54.8251 70.7346 57.5522 71.0539C60.2815 71.3756 73.2757 59.8242 71.9961 56.1298C70.7095 52.4425 57.5825 41.3759 57.5825 40.1709C57.5825 38.9659 71.6721 24.5242 71.3505 22.2797C71.0288 20.0351 58.0346 7.84039 55.7854 9.28548C53.5432 10.7282 42.7306 24.2422 40.7541 24.0837C38.7846 23.9206 26.5782 8.67715 24.3313 8.51632Z" fill="#CD3A43" />
        <path className={pointer && "pointer"} d="M25.7157 11.0546C23.9909 11.276 14.7587 20.3358 14.9941 22.4871C15.2272 24.6384 27.4149 38.798 27.4149 38.798C27.4149 38.798 13.9918 52.8015 13.493 53.9086C12.9966 55.0157 22.8838 63.5907 24.7275 62.9101C26.5711 62.2295 39.6679 47.1842 40.7564 46.9978C41.8449 46.8137 56.3074 62.6724 57.2817 62.8565C58.256 63.0407 68.2225 54.454 68.4066 53.1627C68.5908 51.8715 52.6318 39.0847 53.3683 37.2714C54.1049 35.458 65.9406 22.5943 65.7542 21.3077C65.5677 20.0188 58.3329 12.0591 56.4123 12.0591C54.4918 12.0591 42.008 24.6501 40.7564 24.6501C39.5047 24.6501 27.31 10.8471 25.7157 11.0546Z" fill="#E77457" />
        <path className={pointer && "pointer"} d="M17.8097 59.5351C19.9913 60.3183 25.4151 56.4421 27.0886 55.1882C28.7621 53.9342 30.0627 53.3375 27.4149 56.0156C24.7648 58.696 21.504 62.9474 21.504 62.9474" fill="#CD3A43" />
        <path className={pointer && "pointer"} d="M39.542 25.2887C39.542 25.2887 27.6596 10.2015 26.0444 10.4509C24.4268 10.7003 13.8916 20.5176 14.3578 22.5943C14.8239 24.6734 22.4573 31.2393 22.4573 31.2393C22.4573 31.2393 17.5393 23.8623 18.0894 23.0395C18.6371 22.2167 24.4082 14.5927 26.4686 14.5204C28.5267 14.4528 39.3113 25.9553 39.542 25.2887Z" fill="#E0937D" />
        <path className={pointer && "pointer"} d="M21.6112 45.5341C21.2196 45.9023 14.0361 52.89 14.0781 54.1254C14.12 55.3584 18.4064 59.1389 19.2175 59.2345C20.0286 59.33 22.9281 58.0831 25.6318 55.8269C25.6318 55.8269 20.257 57.808 19.2058 57.1927C18.157 56.575 16.2364 54.1417 16.2154 53.3818C16.1921 52.6243 21.6112 45.5341 21.6112 45.5341Z" fill="#E0937D" />
        <path className={pointer && "pointer"} d="M52.8579 38.798C53.9091 39.9191 67.9568 51.7922 68.4113 52.7805C68.8658 53.771 59.9412 61.197 59.9412 61.197C59.9412 61.197 64.3208 54.7663 63.9245 53.4261C63.5306 52.0859 52.8579 38.798 52.8579 38.798Z" fill="#E0937D" />
        <path className={pointer && "pointer"} d="M68.4089 24.4007C68.4089 24.4007 51.5339 28.3421 50.3848 26.8644C49.2358 25.3866 49.4922 15.8327 49.4922 15.8327L46.595 18.9956C46.595 18.9956 47.9259 27.6825 49.5667 28.8316C51.2076 29.9783 62.3675 30.307 62.3675 30.307" fill="#CD3A43" />
        <path className={pointer && "pointer"} d="M29.5918 63.3483C29.5918 63.3483 33.0624 46.3685 31.5543 45.2637C30.0463 44.1565 20.504 44.674 20.504 44.674L23.5853 41.6906C23.5853 41.6906 32.3049 42.779 33.4982 44.3873C34.6916 45.9979 35.3279 57.1414 35.3279 57.1414" fill="#CD3A43" />
        <path className={pointer && "pointer"} d="M30.5777 14.2874C30.5777 14.2874 34.4399 29.0856 32.8829 30.1251C31.3282 31.1623 20.504 31.5283 20.504 31.5283L23.4502 34.6469C23.4502 34.6469 33.5239 32.6377 34.7872 31.0854C36.0528 29.5331 35.265 19.3219 35.265 19.3219" fill="#CD3A43" />
        <path className={pointer && "pointer"} d="M64.3511 48.0257C64.3511 48.0257 50.8651 45.4082 49.709 46.7204C48.9002 47.6434 50.5388 59.0783 50.5388 59.0783L47.9679 56.004C47.9679 56.004 47.3945 46.5036 48.9725 45.4991C50.5528 44.4992 59.6009 43.7277 59.6009 43.7277" fill="#CD3A43" />
    </svg>
}

export default Shell;